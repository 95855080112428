import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import Link from '../link/link';
import Styles from './full-width-image.module.scss';

export default props => {
    if (!props.fluid) {
        return null;
    }

    const inner = (
        <React.Fragment>
            {
                props.link ? (
                    <div className={`position-absolute container ${Styles.inner}`}>
                        <div className={`row ${Styles.row}`}>
                            <div className="position-relative ml-auto mr-4 p-4 bg-white">
                                {
                                    props.link.to ? (
                                        <React.Fragment>
                                            <Link className="stretched-link unstyled" to={props.link.to}>
                                                <span className="font-playfair">{props.link.text}</span>
                                            </Link>
                                            <svg className="align-baseline d-inline ml-3" width="24" height="24" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22 0L18.1225 3.8775L33.4675 19.25H0V24.75H33.4675L18.1225 40.1225L22 44L44 22L22 0Z" className="fill-gold" />
                                            </svg>
                                        </React.Fragment>
                                    ) : (
                                        <span className="font-playfair" style={{fontSize: '1.7rem', lineHeight: '135%'}}>{props.link.text}</span>
                                    )
                                }
                                {
                                    props.link.subtitle ? (
                                        <div className={`color-grey`}>
                                            <span>{props.link.subtitle}</span>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    </div>
                ) : ''
            }
            {
                props.customInnerHtml ? (<div dangerouslySetInnerHTML={{ __html: props.customInnerHtml }}></div>) : ''
            }
        </React.Fragment>
    );

    const style = {
        height: props.customHeight ? props.customHeight : undefined
    };

    const className = `${Styles.section} ${props.customHeight ? Styles.customHeight : ''} ${props.link ? 'position-relative' : ''} ${props.additionalClasses || ''}`;

    return (
        <BackgroundImage
            Tag={props.tag || 'section'}
            className={className}
            fluid={props.fluid}
            backgroundColor={`#403A3D`}
            style={style}
        >
            {inner}
        </BackgroundImage>
    );
}
